<script lang="ts">
  import type { MenuCategory, MenuItem } from "@internalTypes";
  import { isMenuItem } from "@internalTypes";
  import { link } from "svelte-navigator/src";
  import { useLocation } from "svelte-navigator";
  import { createEventDispatcher } from "svelte";

  import slugify from "slugify";

  export let menuItems: (MenuItem | MenuCategory)[];
  export let open = "";

  const dispatch = createEventDispatcher();
  const location = useLocation();
  const toggleOpen = (event) => (open = event.target.closest(".menu__catagory").dataset.uid);
  const toggleClose = (event) => {
    setTimeout(() => {
      const currentlyOpenMenu = document.querySelector(`[data-uid="${open}"]`);
      if (!currentlyOpenMenu.contains(event.relatedTarget)) {
        open = "na";
      }
    }, 100);
  };

  const genSlug = (slug) => `${slug.startsWith("/") ? "" : "/"}${slug}${slug.endsWith("/") ? "" : "/"}`;
</script>

{#each menuItems as menuItem}
  {#if isMenuItem(menuItem)}
    {#if $location.pathname === menuItem.slug}
      <span aria-roledescription="menuitem" class="menu__link menu__link--current">
        {menuItem.title || "Unkown Page"}
      </span>
    {:else}
      <a aria-roledescription="menuitem" use:link href={genSlug(menuItem.slug)} class="menu__link">
        {menuItem.title || "Unkown Page"}
      </a>
    {/if}
  {:else}
    <div
      class="menu__catagory"
      tabindex="0"
      on:focusin={toggleOpen}
      on:focusout={toggleClose}
      on:mouseenter={toggleOpen}
      on:mouseleave={toggleClose}
      aria-expanded={open === slugify(menuItem.title)}
      aria-haspopup="true"
      aria-roledescription="menuitem"
      data-uid={slugify(menuItem.title)}
    >
      <span class="menu__catagory__title menu__link">{menuItem.title || "Unkown Category"}</span>
      <div class="menu__catagory__content" aria-hidden={open !== slugify(menuItem.title)}>
        <svelte:self menuItems={menuItem.pages} />
      </div>
    </div>
  {/if}
{/each}

<style lang="scss">
  .menu__catagory {
    position: relative;
    &__content {
      position: absolute;
      background: $secondary;
      display: inline-flex;
      width: 200%;
      left: 0;
      top: 100%;
      flex-direction: column;
      &[aria-hidden="true"] {
        display: none;
      }
    }
    :global(.menu__catagory__content .menu__link) {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      color: $light;
      text-decoration: none;
      display: block;
      &:hover {
        background: $primary;
        color: $dark;
        &.menu__link--current {
          background: $tertiary;
        }
      }
    }
  }

  :global(.mobile-nav .menu__link) {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: $light;
    text-decoration: none;
    display: block;
    &:hover {
      background: $primary;
      color: $dark;
      &.menu__link--current {
        background: $tertiary;
      }
    }
  }

  :global(.mobile-nav .menu__catagory .menu__catagory__content) {
    position: relative;
    background: none;
  }
</style>
