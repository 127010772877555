<script lang="ts">
  import { Router, Route } from "svelte-navigator/src";
  import PageTemplate from "@templates/page.template.svelte";
  import BlogRouter from "@templates/blog-router.template.svelte";
  import FormTemplate from "@templates/form.template.svelte";
  import Header from "@components/header.component.svelte";
  import Footer from "@components/footer.component.svelte";
  import TdJson from "./components/td-json.component.svelte";
  import type { Page, BlogPost, Form } from "@internalTypes";
  import { onMount, afterUpdate } from "svelte";
  export let url = "";

  export let pageUrlList = {};
  export let pageObjects: { [key: string]: Page } = {};
  export let totalBlogPages = 0;
  export let loadedBlogPages = 0;
  export let listedBlogPosts = [];
  export let post: BlogPost = null;
  export let form: Form = null;
  export let reviews: any[] = null;
  export const menus = {};
  let usingMouse = true;

  const fetchPageList = async () => {
    const req = await fetch("/data/pages/index.json");
    pageUrlList = await req.json();
  };

  onMount(async () => {
    await fetchPageList();
    document.body.addEventListener("mousedown", function () {
      document.body.classList.add("using-mouse");
    });

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener("keydown", function (event) {
      if (event.keyCode === 9) {
        document.body.classList.remove("using-mouse");
      }
    });
  });

  afterUpdate(() => {
    for (const e of Array.from(document.head.querySelectorAll("meta[data-svelte]"))) {
      e.remove();
    }
  });
  const genSlug = (slug) => `${slug.startsWith("/") ? "" : "/"}${slug}${slug.endsWith("/") ? "" : "/"}`;
  const loc = () => {
    try {
      const l = location && location.pathname;
      return l;
    } catch(e) {
      return false;
    }
  }
</script>
{#if url === '/' || loc() === "/"}
  <TdJson {reviews}/>
{/if}

<svelte:body
  on:mousedown={() => (usingMouse = true)}
  on:keydown={(event) => {
    if (event.keyCode === 9) usingMouse = false;
  }}
  class={usingMouse ? "using-mouse" : ""} />

{#if pageUrlList}
  <Router {url}>
    <Header {menus} />
    {#each Object.keys(pageUrlList) as path}
      <Route path={genSlug(path)}>
        <PageTemplate {pageObjects} {menus} pageId={pageUrlList[path]} />
      </Route>
    {/each}
    <Route path="/blog/:slug">
      <BlogRouter {loadedBlogPages} {totalBlogPages} {listedBlogPosts} {post} />
    </Route>
    <Route path="/blog">
      <BlogRouter {loadedBlogPages} {totalBlogPages} {listedBlogPosts} />
    </Route>
    <Route path="/contact">
      <FormTemplate formId="/contact" {form} />
    </Route>
    <Route path="/forms/:formId">
      <FormTemplate {form} />
    </Route>
    <Footer />
  </Router>
{/if}

<style lang="scss">
  :global(h1, h2, h3, h4, h5, h6) {
    font-family: $titleFont;
  }
  :global(body) {
    padding: 0;
    margin: 0;
    font-family: $bodyFont;
    background-color: $dark;
  }

  :global(*, *::after, *::before) {
    box-sizing: border-box;
  }

  :global(:focus) {
    outline: #08f auto 2px;
  }

  :global(body.using-mouse :focus) {
    outline: none;
  }
</style>
