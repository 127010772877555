<script lang="ts">
  import type { ContentBlock } from "@internalTypes";
  import { BlockTypes } from "@internalTypes";
  import RichTextRenderer from "@components/rich-text-renderer.component.svelte";
  import { getCorrectImage } from "@lib";
  export let block: ContentBlock;

  const blockType = () => {
    if (block.blockStyle === BlockTypes.hover) {
      return block.image ? "hover" : "standard";
    }

    return block.blockStyle.toString();
  };
</script>

<div class="content-block-container" data-card-type={blockType()}>
  <div class="content-block">
    {#if block.image}
      <img src={getCorrectImage(block.image, true)} alt={block.image.description} class="content-block__image" />
    {/if}
    <h2 class="content-block__title">{block.title}</h2>
    <div class="content-block__inner">
      <RichTextRenderer richText={block.content} />
    </div>
    {#if block.actions && block.actions.length}
      <div class="content-block__actions">
        {#if block.actions[0]}<a class="btn" href={block.actions[0].url}>{block.actions[0].text}</a>{/if}
        {#if block.actions[1]}<a class="btn btn--secondary" href={block.actions[1].url}>{block.actions[1].text}</a>{/if}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  :global(.content-block-container) {
    width: 100%;
    display: block;
    position: relative;
    margin: 0 auto 2rem;
    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        grid-area: section#{$i};
      }
    }
  }
  .content-block {
    background: $light;
    padding: 0;
    border-radius: 0.2rem;
    position: relative;
    display: block;
    box-shadow: 0 3px 6px rgba(169, 126, 126, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    &__title {
      color: $secondary;
      padding: 1rem 1rem 0;
      margin: 0;
      font-weight: 600;
      font-size: 1.4rem;
      text-transform: uppercase;
    }

    &__image {
      height: 20rem;
      object-fit: cover;
      object-position: center;
      display: block;
      width: 100%;
      @include mq($until: tablet) {
        height: 15rem;
      }
    }

    &__inner {
      padding: 0 1rem 1rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: $dark;
    }

    &__actions {
      padding: 0 1rem 1rem;
      display: flex;
      & > .btn {
        margin-right: 0.5rem;
      }
    }
  }

  .content-block-container[data-card-type="hover"] {
    padding-top: 12rem;
    .content-block__image {
      height: 12rem;
      width: 40%;
      background-color: $tertiary;
      margin: 0 auto 1rem;
      border-radius: 0.2rem;
      position: relative;
      box-shadow: 0 3px 6px rgba(169, 126, 126, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      transform: scale(2) translateY(-25%);
    }

    @include mq($until: tablet) {
      padding-top: 8rem;
      .content-block__image {
        height: 8rem;
      }
    }
  }
</style>
