<script lang="ts">
  import { BLOCKS, MARKS } from "@contentful/rich-text-types";
  import type { Document as richDocument } from "@contentful/rich-text-types/dist/types/types";
  import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
  import { getCorrectImage } from "@lib";

  export let richText: richDocument | null;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) =>
        `<img src="${getCorrectImage(data.target.fields.file)}" alt="${data.target.fields.description}"/>`,
    },
  };

  const renderedText = (): string => richText && documentToHtmlString(richText, options);
</script>

<div class="user-content">
  {@html renderedText()}
</div>

<style lang="scss">
  :global(.user-content) {
    color: $dark;
  }
  :global(.user-content p) {
    font-family: $bodyFont;
  }
  :global(.user-content a) {
    color: darken($primary, 20%);
    font-weight: 600;
    text-decoration: none;
  }
  :global(.user-content a:visited) {
    color: darken($primary, 20%);
    font-weight: 600;
  }
  :global(.user-content blockquote) {
    background: darken($light, 6%);
    border-left: 10px solid lighten($dark, 20%);
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
    color: $dark;
    border-radius: 0.2rem;
  }
  :global(.user-content blockquote:before) {
    color: lighten($dark, 30%);
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  :global(.user-content blockquote p) {
    display: inline;
    color: $dark;
  }

  :global(.user-content code) {
    border-radius: 0.2rem;
    font-size: 0.8rem;
    font-family: $bodyFont;
    background: lighten(#292a2b, 2%);
    color: #e6e6e6;
    padding: 1rem;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  :global(.user-content img) {
    display: block;
    border: 8px solid lighten($dark, 20%);
    margin: 0 auto 1rem;
    max-width: 70%;
    border-radius: 0.4rem;
    box-shadow: 0 3px 6px rgba(169, 126, 126, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
</style>
