<script lang="ts">
  export let tradingName = "TruNLP";
  export let registeredName = "TruNLP Training Ltd";
  export let copyrightStart = 2021;
  export let companyRegistrationNumber = 12894438;
  export let registeredIn = "England and Wales";

  if (tradingName.length === 0) tradingName = registeredName;

  const today = new Date();
  const thisYear = today.getFullYear();
  const copyright = `© ${copyrightStart}${thisYear > copyrightStart ? `-${thisYear}` : ""} ${tradingName}.`;
  const companyNumber = `Company Number ${companyRegistrationNumber}`;
  const tradingNotice =
    tradingName !== registeredName
      ? `${tradingName} is the trading name of ${registeredName}, a registered company in ${registeredIn}.`
      : `${registeredName} is a registered company in ${registeredIn}`;
</script>

<footer class="primary-footer">
  <div class="primary-footer__copyright">
    {copyright}<br />
    {tradingNotice} ({companyNumber})
  </div>
</footer>

<style lang="scss">
  .primary-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: lighten($dark, 20%);
    color: $light;
    font-family: $bodyFont;
    padding: 1rem;

    &__copyright {
      max-width: 40rem;
      text-align: center;
    }
  }
</style>
