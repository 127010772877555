<script lang="ts">
  import type { image, heroContent } from "@internalTypes";
  import { slide } from "svelte/transition";
  import { getCorrectImage } from "@lib";

  export let hero: heroContent;
</script>

<div
  class="hero"
  style={`background-image: url('${getCorrectImage(hero.image)}');`}
  in:slide={{ duration: 420, delay: 450 }}
  out:slide={{ duration: 420, delay: 0 }}
>
  <h1 class="hero__title">{hero.title}</h1>
  {#if hero.tagline}
    <span class="hero__tagline">{hero.tagline}</span>
  {/if}
</div>

<style lang="scss">
  .hero {
    display: flex;
    flex-direction: column;
    position: relative;
    height: least-squares-fit(
      (
        500px: 400px,
        640px: 400px,
        720px: 450px,
        1080px: 450px
      )
    );
    background-repeat: no-repeat;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: $secondary;
    overflow: hidden;
    animation: heroAnim 45s infinite alternate ease-out;
    background-size: 130% auto;
    background-position: center;
    margin-bottom: -43px;

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 2;
      background-color: $secondary;
      opacity: 0.8;
      content: "";
    }

    &__tagline {
      font-size: least-squares-fit(
        (
          576px: 18px,
          768px: 18px,
          992px: 24px,
          2000px: 24px
        )
      );
      color: $light;
      opacity: 0.8;
      font-family: $titleFont;
      font-weight: 400;
      z-index: 3;
      margin-top: 1rem;
      max-width: 80%;
      text-align: center;
    }

    &__title {
      padding: 0;
      margin: 0;
      z-index: 3;
      color: $light;
      font-family: $titleFont;
      font-weight: 400;
      opacity: 0.9;
      max-width: 90%;
      text-align: center;
      animation: heroTitleAnim 30s infinite alternate ease-out;
      font-size: least-squares-fit(
        (
          576px: 34px,
          768px: 34px,
          992px: 56px,
          2000px: 56px
        )
      );
    }
  }
</style>
