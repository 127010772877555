<script lang="ts">
  import type { heroContent, Page } from "@internalTypes";
  import { headerStyles } from "@internalTypes";
  import { onMount } from "svelte";
  import ContentBlockRenderer from "@components/content-block.component.svelte";
  import { fade } from "svelte/transition";
  import { currentPage } from "@stores";

  export let pageId = "";
  export let pageObjects = {};
  export let page: Page = pageObjects[pageId];
  let loading = false;

  const genSlug = () =>
    `https://trunlp.com${page.slug.startsWith("/") ? "" : "/"}${page.slug}${page.slug.endsWith("/") ? "" : "/"}`;

  let useBigHero = false;
  let slug = page && page.slug ? genSlug() : "";

  onMount(async () => {
    currentPage.set(null);
    loading = true;
    page = await fetch(`/data/pages/${pageId}.json`).then((p) => p.json());
    slug = genSlug();
    useBigHero = page && page.headerImage && page.headerStyle === headerStyles.bigHero;
    currentPage.set(page);
    loading = false;
  });
</script>

<svelte:head>
  {#if page}
    <title>TruNLP - {page.contentTitle}</title>
    <meta property="og:type" content="article" />
    <meta property="og:url" content={slug} />
    <meta property="og:title" content={`${page.title} - TruNLP`} />
    <meta name="twitter:title" content={`${page.title} - TruNLP`} />
    <meta
      property="og:description"
      content="Welcome to the start of the most important personal development journey you are ever going to take. Let TRU guide you on the path to true transformation for body and mind"
    />
    <meta
      name="twitter:description"
      content="Welcome to the start of the most important personal development journey you are ever going to take. Let TRU guide you on the path to true transformation for body and mind"
    />
    <meta
      name="description"
      content="Welcome to the start of the most important personal development journey you are ever going to take. Let TRU guide you on the path to true transformation for body and mind"
    />
  {/if}
</svelte:head>

{#if page}
  <main class="main" in:fade={{ duration: 210, delay: 250 }} out:fade={{ duration: 210, delay: 0 }}>
    {#if !useBigHero}
      <h1 class="basic-title">{page.title}</h1>
    {/if}
    <div class="content-blocks" data-layout={page.pageLayout}>
      {#each page.contentBlocks as block}
        <ContentBlockRenderer {block} />
      {/each}
    </div>
  </main>
{/if}

<style lang="scss">
  .basic-title {
    font-size: 3rem;
    text-align: center;
    color: $light;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: -2rem;
    padding: 2rem 1rem;
  }

  .content-blocks {
    display: block;
    max-width: 100%;
    width: 70rem;
    padding: 0 2rem;
    margin: 0 auto;

    @include mq($from: tablet) {
      &[data-layout="121"] {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: repeat(2, calc(50% - 1rem));
        column-gap: 2rem;
        grid-template-areas:
          "section1 section1"
          "section2 section3"
          "section4 section4"
          "section5 section6"
          "section7 section7"
          "section8 section9";
      }

      &[data-layout="212"] {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: repeat(2, calc(50% - 1rem));
        column-gap: 2rem;
        grid-template-areas:
          "section1 section2"
          "section3 section3"
          "section4 section5"
          "section6 section6"
          "section7 section8"
          "section9 section9";
      }

      &[data-layout="112"] {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: repeat(2, calc(50% - 1rem));
        column-gap: 2rem;
        grid-template-areas:
          "section1 section1"
          "section2 section2"
          "section3 section4"
          "section5 section5"
          "section6 section6"
          "section7 section8"
          "section9 section9"
          "section10 section10";
      }

      &[data-layout="Two Columns"] {
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: repeat(2, calc(50% - 1rem));
        column-gap: 2rem;
        grid-template-areas:
          "section1 section2"
          "section3 section4"
          "section5 section6"
          "section7 section8"
          "section9 section10";
      }
    }
  }
</style>
