<script lang="ts">
  import { FormFieldTypes } from "@internalTypes";
  import type { FormSelect, FormField } from "@internalTypes";
  let vlu = "";

  export let field: FormField | FormSelect | null;
  function isSelect(field: FormField | FormSelect): field is FormSelect {
    return field.type === FormFieldTypes.select;
  }

  const mobileCheck = (event: { target: HTMLInputElement }) => {
    const regex = /(^0[1-9]\d{1}\s\d{4}\s?\d{4}$)|(^0[1-9]\d{2}\s\d{3}\s?\d{4}$)|(^0[1-9]\d{2}\s\d{4}\s?\d{3}$)|(^0[1-9]\d{3}\s\d{3}\s?\d{2}$)|(^0[1-9]\d{3}\s\d{3}\s?\d{3}$)|(^0[1-9]\d{4}\s\d{3}\s?\d{2}$)|(^0[1-9]\d{4}\s\d{2}\s?\d{3}$)|(^0[1-9]\d{4}\s\d{2}\s?\d{2}$)/gm;
    event.target.setCustomValidity("");
    if (regex.exec(vlu) !== null) {
      event.target.setCustomValidity("Invalid UK Phone Number");
    }
  };
</script>

{#if field}
  {#if isSelect(field)}
    <select required value={field.default} name={field.name} id={`form-${field.name.replace(/ /g, "-")}`}>
      {#each field.options as option}
        <option value={option.value || ""}>{option.label || ""}</option>
      {/each}
    </select>
  {:else if field.type === FormFieldTypes.ukNumber}
    <label for={`form-${field.name.replace(/ /g, "-")}`}>{field.label || ""}</label>
    <input
      type="text"
      id={`form-${field.name.replace(/ /g, "-")}`}
      name={field.name}
      placeholder={field.placeholder || ""}
      on:change={mobileCheck}
      bind:value={vlu}
      required
    />
  {:else}
    <label for={`form-${field.name.replace(/ /g, "-")}`}>{field.label || ""}</label>
    <input
      type={field.type}
      id={`form-${field.name.replace(/ /g, "-")}`}
      name={field.name}
      placeholder={field.placeholder || ""}
      required
    />
  {/if}
{/if}

<style lang="scss">
  label {
    text-transform: uppercase;
    font-family: $titleFont;
    text-align: right;
    padding: 0.25rem 0;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 34px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0 0.5rem;
    font-family: $titleFont;
    @include mq($until: tablet) {
      margin-bottom: 1rem;
    }

    &::placeholder {
      font-family: $titleFont;
    }
  }
</style>
