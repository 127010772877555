<script lang="ts">
  import type { MenuItem, Page, heroContent, MenuCategory } from "@internalTypes";
  import { headerStyles } from "@internalTypes";
  import { onMount } from "svelte";
  import { currentPage } from "@stores";

  import { link } from "svelte-navigator/src";
  import Hero from "./hero.component.svelte";
  import Menu from "./menu.component.svelte";

  export let hero: heroContent | null;
  export let menus: { [menuSlot: string]: [MenuItem | MenuCategory] } = {};
  let openMobileNav = false;
  const closeNav = () => (openMobileNav = false);
  currentPage.subscribe((page: Page | null) => {
    hero =
      page && page.headerImage && page.headerStyle === headerStyles.bigHero
        ? {
            title: page.contentTitle,
            image: page.headerImage,
            tagline: page.tagline,
          }
        : null;
  });

  const addStaticPages = () => {
    if (menus.main) {
      menus.main.push(
        {
          title: "News",
          slug: "/blog",
        },
        {
          title: "Contact Us",
          slug: "/contact",
        }
      );
    }
  };

  onMount(async () => {
    menus = await (await fetch(`/data/menus.json`)).json();
    addStaticPages();
  });

  //Functions to run on the server
  addStaticPages();
</script>

<svelte:window on:popstate={closeNav} on:pushstate={closeNav} />

<div class="topnav">
  <a use:link href="/" class="topnav__link ">
    <span class="topnav__logo">φ</span> TruNLP
  </a>
</div>
{#if hero}
  <Hero {hero} />
{/if}
<nav class="undernav undernav-main" aria-roledescription="menubar">
  {#if menus.main}
    <Menu menuItems={menus.main} />
  {/if}
</nav>
<nav class="undernav undernav-mobile" data-open={openMobileNav} aria-roledescription="menubar">
  <button
    class={openMobileNav ? "navMenuButton opened" : "navMenuButton"}
    on:click={() => (openMobileNav = !openMobileNav)}
    aria-label="Main Menu"
  >
    <svg width="32" height="32" viewBox="0 0 100 100">
      <path
        class="mobileMenuLine mobileMenuLine1"
        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
      />
      <path class="mobileMenuLine mobileMenuLine2" d="M 20,50 H 80" />
      <path
        class="mobileMenuLine mobileMenuLine3"
        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
      />
    </svg>
  </button>
  {#if openMobileNav}
    <div class="mobile-nav">
      {#if menus.main}
        <Menu menuItems={menus.main} />
      {/if}
    </div>
  {/if}
</nav>

<style lang="scss">
  .navMenuButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    padding: 5px;
    path {
      stroke: $light;
    }
  }
  .mobileMenuLine {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

    &1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
      .opened & {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }

    &2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
      .opened & {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }
    }

    &3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
      .opened & {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }
  }

  .mobile-nav {
    position: fixed;
    display: block;
    content: "";
    top: calc(5rem + 43px);
    bottom: 0;
    left: 0;
    right: 0;
    background: $dark;
  }

  .undernav {
    position: sticky;
    left: 0;
    right: 0;
    top: 5rem;
    background-color: $secondary;
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 101;
    box-shadow: 0 3px 6px rgba(169, 126, 126, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    font-family: $titleFont;
    margin-bottom: 2rem;
  }

  .undernav-main {
    @include mq($until: tablet) {
      display: none;
    }
  }

  .undernav-mobile {
    height: 43px;
    &[data-open="true"] {
      position: fixed;
      top: 5rem;
    }
    @include mq($from: tablet) {
      display: none;
    }
  }

  :global(.undernav > .menu__link, .undernav > .menu__catagory > .menu__catagory__title) {
    text-decoration: none;
    padding: 0.5rem 1rem;
    color: $light;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    transition: 110ms ease-in-out;
    transition-property: transform;

    &:hover {
      transform: scale(1.1);
    }
  }
  .topnav {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    background: $light;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 700;
    z-index: 100;
    font-family: $titleFont;

    &__logo {
      font-size: 4rem;
      margin-right: 0.5rem;
      font-family: $logoFont;
      line-height: 4rem;
      transform: translateY(-0.75rem);
    }

    &__link {
      text-decoration: none;
      padding: 0.5rem;
      color: $dark;
      font-size: 2rem;
      display: inline-flex;
      align-items: center;
    }
  }
</style>
