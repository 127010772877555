<script lang="ts">
  import type { BlogListEntry } from "@internalTypes";
  import { currentPage, storedBlogPages } from "@stores";
  import { onMount } from "svelte";
  import { link } from "svelte-navigator/src";
  import { relativeDate } from "@lib";

  let loading = false;
  export let totalPages = 0;
  export let maxPage = 0;
  export let listedBlogs: BlogListEntry[] = [];

  const getNextPage = async () => {
    if (maxPage === 0) {
      const settings = await (await fetch(`/data/blog-posts/config.json`)).json();
      maxPage = settings.pages;
    }
    if (totalPages >= maxPage) {
      return;
    }

    const newBlogs: BlogListEntry[] = await (await fetch(`/data/blog-posts/page${totalPages}.json`)).json();
    storedBlogPages.set([...listedBlogs, ...newBlogs]);
    totalPages++;
  };

  onMount(async () => {
    currentPage.set(null);
    storedBlogPages.subscribe((val) => (listedBlogs = val));
    if (listedBlogs.length === 0) {
      loading = true;
      await getNextPage();
      loading = false;
    }
  });

  const genSlug = (slug) => `${slug.startsWith("/") ? "" : "/"}${slug}${slug.endsWith("/") ? "" : "/"}`;
</script>

<svelte:head>
  <title>TruNLP Blog</title>
  <meta name="twitter:card" content="summary" />
  <meta property="og:type" content="article" />
  <meta property="og:url" content={`https://trunlp.com/blog`} />
  <meta property="og:title" content="TruNLP Blog" />
  <meta name="twitter:title" content="TruNLP Blog" />
  <meta property="og:description" content="The TruNLP Blog. Read our latest posts here." />
  <meta name="twitter:description" content="The TruNLP Blog. Read our latest posts here." />
  <meta name="description" content="The TruNLP Blog. Read our latest posts here." />
</svelte:head>

<h1 class="basic-title">Blog</h1>

<div class="blog-list">
  {#each listedBlogs as listedPost}
    <a use:link href={genSlug(`/blog/${listedPost.slug}`)} class="blog-item">
      {#if listedPost.image}
        <img class="blog-item__image" src={listedPost.image.url} alt={listedPost.title} />
      {/if}
      <div class="blog-item__info">
        <h3 class="blog-item__title">{listedPost.title}</h3>
        <span class="blog-item__date">{relativeDate(listedPost.publishDate)}</span>
      </div>
    </a>
  {/each}
</div>
{#if totalPages < maxPage}
  <button class="btn btn--blog" on:click={getNextPage}>Load More</button>
{/if}

<style lang="scss">
  .blog-list {
    padding: 0 2em;
    margin: 0 auto 2em;
    max-width: 100%;
    width: 70rem;
    display: grid;
    grid-gap: 2em; /* [1] Add some gap between rows and columns */
    grid-template-columns: repeat(
      auto-fill,
      minmax(12.5rem, 1fr)
    ); /* [2] Make columns adjust according to the available viewport */
    grid-auto-rows: 250px; /* [3] Set the height for implicitly-created row track */

    @include mq($until: tablet) {
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }
  }
  .blog-item {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: 210ms ease-in-out;
    transition-property: background-color;
    @include mq($from: tablet) {
      max-width: 20rem;
    }
    @include mq($until: tablet) {
      max-width: 100%;
    }

    &__info {
      color: $light;
      z-index: 2;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: 1rem;
      margin: 0;
      display: block;
    }

    &__title {
      padding: 0;
      margin: 0;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: brightness(50%);
      transform: scale(1);
      transition: 210ms ease-in-out;
      transition-property: transfrom filter;
    }

    &:hover {
      .blog-item__image {
        filter: brightness(70%);
        transform: scale(1.1);
      }

      &:nth-child(2n) {
        background-color: darken($primary, 12%);
      }
      &:nth-child(2n + 1) {
        background-color: darken($tertiary, 12%);
      }
    }

    &:nth-child(2n) {
      background-color: $primary;
      .blog-item__info:first-child {
        color: $dark;
      }
    }

    &:nth-child(2n + 1) {
      background-color: $tertiary;
      .blog-item__info:first-child {
        color: $dark;
      }
    }

    @include mq($from: tablet) {
      &:nth-child(5n):not(:nth-last-child(-n + 4)) {
        grid-row-end: span 2;
      }

      &:nth-child(5n + 3):not(:nth-last-child(-n + 4)) {
        grid-row-end: span 2;
      }
    }
  }
  .basic-title {
    font-size: 3rem;
    text-align: center;
    color: $light;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: -2rem;
    padding: 2rem 1rem;
  }
</style>
