<script lang="ts">
  import { useParams } from "svelte-navigator/src";
  import BlogTemplate from "@templates/blog.template.svelte";
  import BlogPostTemplate from "@templates/blog-post.template.svelte";
  import type { BlogPost } from "@internalTypes";
  const params = useParams();

  export let totalBlogPages = 0;
  export let loadedBlogPages = 0;
  export let listedBlogPosts = [];
  export let post: BlogPost | null = null;
</script>

{#if post !== null || ($params.slug && $params.slug.length > 0)}
  <BlogPostTemplate {post} />
{:else}
  <BlogTemplate totalPages={loadedBlogPages} maxPage={totalBlogPages} listedBlogs={listedBlogPosts} />
{/if}
