<script lang="ts">
import { generateBusinessInfo } from "@lib/generate-business-info.function";
import { onMount } from "svelte";
export let reviews: any[] = null;
onMount(async () => {
  const req = await fetch("/data/reviews.json");
  reviews = await req.json();
});
</script>
<svelte:head>
  {@html generateBusinessInfo(reviews) }
</svelte:head>