<script lang="ts">
  import FormField from "@components/form-field.component.svelte";
  import type { Form } from "@internalTypes";
  import { onMount } from "svelte";
  import { currentPage } from "@stores";
  import { useParams } from "svelte-navigator/src";
  
  const params = useParams();
  let submitted = false;
  let sent = false;
  let domForm;
  const submit = async (event: Event) => {
    event.preventDefault();
    submitted = true;
    const valid = domForm.reportValidity();
    if (valid) {
      sent = true;
      const domFormValues = new FormData(domForm);
      const values = [...domFormValues].reduce((body, pair) => {
        body[pair[0]] = pair[1];
        return body;
      }, {});
      fetch("https://formspree.io/f/xleaepqg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } as any,
        body: JSON.stringify(values),
      });
    } else {
      submitted = false;
    }
  };

  export let formId = "";
  export let form: Form;
  onMount(async () => {
    currentPage.set(null);
    form = (await fetch("/data/forms.json").then((data) => data.json()))[formId || `/${$params.formId}`];
  });
</script>

<svelte:head>
  {#if form}
    <title>TruNLP Blog - {form.title}</title>
    <meta name="description" content={form.description || ""} />
    <meta property="og:type" content="article" />
    <meta property="og:url" content={`https://trunlp.com/forms${form.slug}`} />
    <meta property="og:title" content={`${form.title} - TruNLP`} />
    <meta name="twitter:title" content={`${form.title} - TruNLP`} />
    <meta property="og:description" content={form.description || ""} />
    <meta name="twitter:description" content={form.description || ""} />
    <meta name="description" content={form.description || ""} />
  {/if}
</svelte:head>

{#if form}
  <div class="form-container">
    <h1>{form.title}</h1>
    <p>{form.description}</p>
    <form data-sent={submitted} bind:this={domForm}>
      <div class="form-fields">
        {#each form.fields as field}
          <FormField {field} />
        {/each}
      </div>
      <button class="btn btn--blog" on:click={submit}>Submit</button>
    </form>
    {#if !sent && submitted}
      <p class="reponse-noted">Submission in progress</p>
    {/if}
    {#if sent}
      <p class="reponse-noted">We will be in contact shortly</p>
    {/if}
  </div>
{/if}

<style lang="scss">
  form[data-sent="true"] {
    display: none;
  }

  .reponse-noted {
    font-family: $titleFont;
    text-transform: lowercase;
  }
  .form-container {
    background: $light;
    margin: 0 auto 2em;
    max-width: calc(100% - 2rem);
    width: 40rem;
    border-radius: 0.4rem;
    overflow: hidden;
    padding: 1rem;
    h1,
    p {
      text-align: center;
      max-width: 20rem;
      width: 100%;
      margin: 1rem auto;
      display: block;
    }

    p {
      margin-bottom: 3rem;
    }
  }
  .form-fields {
    @include mq($from: tablet) {
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 1rem 2rem;
      padding-bottom: 1rem;
      justify-content: center;
      align-content: center;
    }
  }
  .btn--blog {
    margin-bottom: 1rem;
  }
</style>
