<script lang="ts">
  import { onMount } from "svelte";
  import type { BlogPost } from "@internalTypes";
  import RichTextRenderer from "@components/rich-text-renderer.component.svelte";
  import TdJsonBlog from "@components/td-json-blog.component.svelte";
  import { useParams } from "svelte-navigator/src";
  import { relativeDate } from "@lib";
  import { currentPage } from "@stores";

  export let post: BlogPost;
  let loading = false;

  const params = useParams();

  onMount(async () => {
    currentPage.set(null);
    loading = true;
    const slug = $params.slug;
    const postId = (await fetch("/data/blog-posts/map.json").then((m) => m.json()))[slug];
    post = await fetch(`/data/blog-posts/content/${postId}.json`).then((m) => m.json());
    loading = false;
  });
</script>

<svelte:head>
  {#if post}
    <title>TruNLP Blog - {post.title}</title>

    <!-- Blog post meta info-->
    <meta name="twitter:card" content={post.image ? "summary_large_image" : "summary"} />
    <meta property="og:type" content="article" />
    <meta property="og:url" content={`https://trunlp.com/blog/${post.slug}`} />
    <meta property="og:title" content={`${post.title} - TruNLP`} />
    <meta name="twitter:title" content={`${post.title} - TruNLP`} />
    <meta property="og:description" content={post.description || ""} />
    <meta name="twitter:description" content={post.description || ""} />
    <meta name="description" content={post.description || ""} />
    {#if post.image}
      <meta property="og:image" content={'https://trunlp.com'+post.image.twitterUrl} />
      <meta property="og:image:type" content='image/webp' />
      <meta name="twitter:image" content={'https://trunlp.com'+post.image.twitterUrl} />
      <meta name="twitter:image:alt" content={post.image.description} />
    {/if}
  {:else}
    <title>TruNLP Blog</title>
  {/if}
</svelte:head>

{#if post}
  <TdJsonBlog {post}/>
  <article class="blogPost">
    <div class="blogPost__header">
      <img src={post.image.url} alt={post.image.description} class="blogPost__header__image" />
      <h1 class="blogPost__header__title">{post.title}</h1>
      <span class="blogPost__header__date">Posted {relativeDate(post.publishDate, false)}</span>
    </div>
    <div class="blogPost__content">
      <RichTextRenderer richText={post.content} />
    </div>
  </article>
{/if}

<style lang="scss">
  .blogPost {
    background: $light;
    margin: 0 auto 2em;
    max-width: calc(100% - 2rem);
    width: 70rem;
    border-radius: 0.4rem;
    overflow: hidden;

    &__content {
      padding: 1rem;
      color: $dark;
      font-family: $bodyFont;
    }

    &__header {
      padding: 2rem 1rem;
      display: block;
      min-height: 20rem;
      position: relative;
      overflow: hidden;
      color: $light;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &__title {
        z-index: 2;
        position: relative;
        font-family: $titleFont;
        padding: 1rem;
        margin: 0;
        font-size: least-squares-fit(
          (
            576px: 34px,
            768px: 34px,
            992px: 56px,
            2000px: 56px,
          )
        );
      }

      &__date {
        z-index: 2;
        position: relative;
      }

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        filter: brightness(60%);
        object-fit: cover;
        object-position: center;
      }
    }
  }
</style>
